export default class checkForm {
  constructor(element) {
    this.dom = element;
    this.inputFlag = false;
    this.selectFlag = false;
    this.textareaFlag = false;
    this.agreeFlag = false;
    this.submitButton = element.querySelector('.btn_submit');

    this.init();
  }

  init() {
    // input の入力チェック
    this.dom.querySelectorAll('input').forEach((el) => {
      el.addEventListener('change', () => {
        this.check();
      });
    });

    // select の入力チェック
    this.dom.querySelectorAll('select').forEach((el) => {
      el.addEventListener('change', () => {
        this.check();
      });
    });

    // textarea の入力チェック
    this.dom.querySelectorAll('textarea').forEach((el) => {
      el.addEventListener('change', () => {
        this.check();
      });
    });

    this.check();
  }

  check() {
    this.inputFlag = true;
    this.emailFlag = true;
    this.passwordFlag = true;
    this.radioFlag = true;
    this.checkboxFlag = true;
    this.selectFlag = true;
    this.textareaFlag = true;

    this.dom.querySelectorAll('input[type="text"]:required').forEach((el) => {
      if (el.value === '') {
        this.inputFlag = false;
      }
    });

    this.dom.querySelectorAll('input[type="email"]:required').forEach((el) => {
      if (el.value === '') {
        this.emailFlag = false;
      }
    });

    this.dom.querySelectorAll('input[type="password"]:required').forEach((el) => {
      if (el.value === '') {
        this.passwordFlag = false;
      }
    });

    this.dom.querySelectorAll('input[type="radio"]').forEach((el) => {
      if (el.value === '') {
        this.radioFlag = false;
      }
    });

    if (this.dom.querySelectorAll('input[type="checkbox"]').length != 0) {
      if (this.dom.querySelectorAll('input[type="checkbox"]:checked').length <= 0) {
        this.checkboxFlag = false;
      }
    }

    this.dom.querySelectorAll('select').forEach((el) => {
      if (el.value === '') {
        this.selectFlag = false;
      }
    });

    this.dom.querySelectorAll('textarea:required').forEach((el) => {
      if (el.value === '') {
        this.textareaFlag = false;
      }
    });

    if (this.inputFlag && this.emailFlag && this.passwordFlag && this.textareaFlag && this.selectFlag && this.radioFlag && this.checkboxFlag) {
      this.submitButton.classList.remove('disable');
    } else {
      this.submitButton.classList.add('disable');
    }
  }
}
