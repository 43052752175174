export const utils = {
  // usage
  // var element = utils.eq('.list > .item', 2);
  // => <div class="item">3</div>
  eq: (selector, index) => {
    var nodeList = document.querySelectorAll(selector),
      length = nodeList.length;

    if (0 <= index) {
      return nodeList[index];
    }

    return null;
  },

  // usage
  // var index = utils.index(document.querySelectorAll('.hoge'), document.querySelector('target'));
  // => 4
  index: (selector, target) => {
    var nodeList = selector,
      element = target;

    // 第2引数を省略したとき
    if (typeof target === "undefined") {
      return Array.prototype.indexOf.call(
        nodeList[0].parentNode.children,
        nodeList[0]
      );
    }

    return Array.prototype.indexOf.call(nodeList, element);
  },

  slideUp: function (target, duration = 500) {
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;

    window.setTimeout(() => {
      target.style.display = "none";
      target.style.removeProperty("height");
      target.style.removeProperty("padding-top");
      target.style.removeProperty("padding-bottom");
      target.style.removeProperty("margin-top");
      target.style.removeProperty("margin-bottom");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
    }, duration);
  },

  slideDown: function (target, duration = 500) {
    target.style.removeProperty("display");
    let display = window.getComputedStyle(target).display;

    if (display === "none") {
      display = "block";
    }

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");

    window.setTimeout(() => {
      target.style.display = display;
      target.style.removeProperty("height");
      target.style.removeProperty("overflow");
      target.style.removeProperty("transition-duration");
      target.style.removeProperty("transition-property");
    }, duration);
  },

  slideToggle: function (target) {
    target.parentElement.classList.toggle("active");

    if (window.getComputedStyle(target).display === "none") {
      return this.slideDown(target);
    } else {
      return this.slideUp(target);
    }
  },

  // usage
  // long_press(ターゲットDOM, 通常時の関数, 長押し時の関数, 長押し判定秒数(ms));
  long_press: (el, nf, lf, sec) => {
    let longclick = false;
    let longtap = false;
    let touch = false;
    let timer;

    el.addEventListener("touchstart", () => {
      touch = true;
      longtap = false;
      timer = setTimeout(() => {
        longtap = true;
        lf();
      }, sec);
    });

    el.addEventListener("touchend", () => {
      if (!longtap) {
        clearTimeout(timer);
        nf();
      } else {
        touch = false;
      }
    });

    el.addEventListener("mousedown", () => {
      if (touch) return;
      longclick = false;
      timer = setTimeout(() => {
        longclick = true;
        lf();
      }, sec);
    });

    el.addEventListener("click", () => {
      if (touch) {
        touch = false;
        return;
      }
      if (!longclick) {
        clearTimeout(timer);
        nf();
      }
    });
  },

  ua: () => {
    let body = document.getElementsByTagName("body")[0];
    const u = navigator.userAgent;

    if (u.indexOf("iPhone") > 0) {
      body.classList.add("is_ios");
    }
    if (u.indexOf("Android") > 0) {
      body.classList.add("is_android");
    }
    if (u.indexOf("MSIE ") > -1 || u.indexOf("Trident/") > -1) {
      body.classList.add("is_ie");
    }
  },

  // marginを含めたwidth heightを返す
  getOuterSize: (element) => {
    const getBoundingSize = (element) => {
      const { width, height } = element.getBoundingClientRect();
      return { width, height };
    };

    const getElementMargin = (element) => {
      const styles = window.getComputedStyle(element);
      return ["top", "right", "bottom", "left"].reduce((obj, key) => {
        return {
          ...obj,
          [key]: parseFloat(styles.getPropertyValue(`margin-${key}`)) || 0,
        };
      });
    };

    const { top, right, bottom, left } = getElementMargin(element);
    const { width, height } = getBoundingSize(element);
    return {
      width: width + right + left,
      height: height + top + bottom,
    };
  },

  // スマホ100vh問題用
  setFillHeight: () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
};
