const axios = require('axios');
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import imagesLoaded from 'imagesLoaded';
import * as SmoothScroll from 'smooth-scroll';

import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import ScrollClass from './class/_scrollClass';
import ScrollLinkDisable from './class/_scrollLinkDisable';
import Lazyload from './class/_lazyload';
import CheckForm from './class/_checkform';
// import Include from './class/_include';
import { utils } from './utils/_util';

barba.use(barbaPrefetch);

let APP = APP || {};
window.APP = APP;

(() => {
  const _html = document.querySelector('html');
  const _body = document.querySelector('body');
  const _container = document.getElementById('container');
  const _wrapper = document.getElementById('wrapper');
  const _gnavi = document.querySelector('#globalNavi');
  const _btnMenu = document.querySelector('#btnMenu');
  const _transitionBG = document.querySelector('#transitionBG');
  const _lang = document.querySelector('.lang');
  const _logo = document.querySelector('.logo');

  let namespace;
  let naviOpen = false;

  // ----------------------------------------------------------
  // Barba関連
  // ----------------------------------------------------------
  function barbaUpdate(data) {
    // コンタクトフォーム
    setContactForm();

    // アコーディオン
    setAccordion(data.next.container.querySelectorAll('.acc'));

    // TOP スライド
    setTopSlide();

    // swiper
    if (data.next.container.querySelectorAll('.swiper').length > 0) {
      setSwiper();
    }

    APP.SC = new ScrollClass(document.querySelectorAll('[data-target]'));
    APP.SC2 = new ScrollClass(document.querySelectorAll('[data-target-mask]'));
    APP.SC_MASK = new ScrollClass(document.querySelectorAll('[data-mask]'));

    // グロナビ カレント表示
    const _navi = document.querySelectorAll('.nav');
    _navi.forEach((el) => {
      el.classList.remove('active');
      if (el.classList.contains(`n_${_body.dataset.page}`)) {
        el.classList.add('active');
      }
    });
  }

  function scroll() {
    // ヘッダー追従かどうか
    var headerFixed = true;

    // URLに「#」が存在するか
    if (location.hash) {
      var anchor = document.querySelector(location.hash);
      if (anchor) {
        setTimeout(() => {
          var rect = anchor.getBoundingClientRect();
          var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          var top = rect.top + scrollTop;
          if (headerFixed) {
            var header = document.getElementById('globalHeader');
            if (header) {
              top = top - (header.clientHeight + 100);
            }
          }

          window.scrollTo(0, top);
        }, 100);
      } else {
        // アンカー先が存在しなければページトップに
        window.scrollTo(0, 0);
      }
    } else {
      // URLに「#」が存在しなければページトップに
      window.scrollTo(0, 0);
    }
  }

  function initPageTransitions() {
    barba.hooks.before(() => {
      // console.log('before');
      _html.classList.add('is-transitioning');
    });

    barba.hooks.beforeLeave((data) => {
      // console.log('beforeLeave');
    });

    barba.hooks.beforeEnter((data) => {
      // console.log('beforeEnter');

      if (data.next.namespace == 'top') {
        _logo.classList.add('white');
        _logo.classList.add('hide');
        _btnMenu.classList.add('white');
        _btnMenu.classList.add('hide');
        // _lang.classList.add('white');
      }
    });

    barba.hooks.enter(() => {
      // console.log('enter');
    });

    barba.hooks.afterEnter((data) => {
      // console.log('afterEnter');
      _body.dataset.page = data.next.namespace;
      namespace = data.next.namespace;

      naviClose();
      // window.scrollTo(0, 0);
      scroll();

      if (namespace != 'top') {
        _logo.classList.remove('white');
        _logo.classList.remove('hide');
        _btnMenu.classList.remove('white');
        _btnMenu.classList.remove('hide');
        _lang.classList.remove('white');
      }
    });

    barba.hooks.after((data) => {
      // console.log('after');
    });

    barba.init({
      sync: true,
      debug: false,
      timeout: 7000,
      prevent: ({ el }) => {
        const href = el.getAttribute('href');

        // アンカーリンクであり同一ページでなければbarbaを有効に
        let url = location.protocol + '//' + location.host + location.pathname;
        let extract_hash = href.replace(/#.*$/, '');
        if (href.startsWith(location.protocol + '//' + location.host)) {
          if (href.indexOf('#') > -1 && extract_hash != url) {
            return false;
          }
        }

        // bogoの言語スイッチャーは無効化
        if (el.getAttribute('hreflang')) {
          return true;
        }
      },
      transitions: [
        {
          name: 'default',
          sync: true,
          once(data) {
            data.next.container.classList.add('enter');
            barbaUpdate(data);

            setTimeout(() => {
              naviClose();
            }, 400);
          },

          async leave(data) {
            const done = this.async();
            data.current.container.classList.add('leave');
            data.next.container.classList.add('hide');
            _transitionBG.classList.add('leave');
            await delay(600);
            done();
          },

          async enter(data) {
            await delay(600);
            const imgLoad = imagesLoaded(_wrapper);
            data.next.container.classList.remove('hide');

            imgLoad.on('always', function (instance) {
              barbaUpdate(data);

              _transitionBG.classList.remove('leave');
              data.next.container.classList.add('enter');
            });
          },
        },
      ],
    });
  }

  // ----------------------------------------------------------
  // 初期化関連
  // ----------------------------------------------------------
  function initCheckTouchDevice() {
    function isTouchScreendevice() {
      return 'ontouchstart' in window || navigator.maxTouchPoints;
    }

    if (isTouchScreendevice()) {
      _html.classList.add('touch');
      _html.classList.remove('no-touch');
    } else {
      _html.classList.remove('touch');
      _html.classList.add('no-touch');
    }

    window.addEventListener('resize', () => {
      if (isTouchScreendevice()) {
        _html.classList.add('touch');
        _html.classList.remove('no-touch');
      } else {
        _html.classList.remove('touch');
        _html.classList.add('no-touch');
      }
    });
  }

  function initSettings() {
    initCheckTouchDevice();

    const SLD = new ScrollLinkDisable();
    APP.SC = new ScrollClass(document.querySelectorAll('[data-target]'));
    APP.SC_MASK = new ScrollClass(document.querySelectorAll('[data-mask]'));

    APP.SS = new SmoothScroll('a[href*="#"]', {
      speed: 600,
      speedAsDuration: true,
      easing: 'easeInOutQuart',
      header: '[data-scroll-header]',
    });

    // グロナビ
    _btnMenu.addEventListener('click', () => {
      naviOpen = naviOpen ? false : true;

      _body.classList.toggle('noscroll');
      _btnMenu.classList.toggle('active');
      _gnavi.classList.toggle('active');

      if (naviOpen) {
        _gnavi.querySelectorAll('[data-target]').forEach((el, index) => {
          addAction(el, index);
        });
      } else {
        _gnavi.querySelectorAll('[data-target]').forEach((el, index) => {
          removeAction(el);
        });
      }
    });

    // グロナビ内アコーディオン
    setAccordion(_gnavi.querySelectorAll('.acc'));
  }

  function setAccordion(target) {
    target.forEach((el) => {
      const _accTrigger = el.querySelector('.acc_trigger');
      const _accBody = el.querySelector('.acc_body');

      utils.slideUp(_accBody);

      _accTrigger.addEventListener('click', () => {
        utils.slideToggle(_accBody);
      });
    });
  }

  function setContactForm() {
    if (document.querySelector('#contactForm')) {
      const _form = document.querySelector('#contactForm');
      const url = _form.getAttribute('action');
      const CHECKFORM = new CheckForm(_form);

      document.querySelector('.btn_submit').addEventListener('click', (e) => {
        const invalids = _form.querySelectorAll(':invalid');

        if (0 < invalids.length) {
          e.preventDefault();

          for (let i = 0, len = invalids.length; i < len; i++) {
            (function (idx) {
              const invalid = invalids[idx];
              const $parent = invalid.closest('.form_input');
              const $errorMessage = $parent.querySelectorAll('.txt_error');

              invalid.classList.add('error');
              $errorMessage.forEach((el) => {
                el.innerHTML = `※ ${invalid.validationMessage}`;
                el.classList.add('active');
              });

              invalid.addEventListener('change', function f() {
                this.removeEventListener('change', f);
                $errorMessage.forEach((el) => {
                  el.innerHTML = '必須';
                  el.classList.remove('active');
                });
                invalid.classList.remove('error');
              });
            })(i);
          }
        }
      });

      _form.addEventListener('submit', (e) => {
        e.preventDefault();
        const formdata = new FormData(document.forms.contactForm);

        axios
          .post(url, formdata)
          .then(function (response) {
            _form.classList.add('hide');
            document.querySelector('.form_inner').insertAdjacentHTML('afterbegin', response.data);

            APP.SC.update(document.querySelectorAll('[data-target]'));
          })
          .catch(function (error) {
            console.log(error);
          })
          .then(function () {
            // always executed
          });
      });
    }
  }

  function setSwiper() {
    const swiper = new Swiper('.swiper', {
      modules: [Pagination, Autoplay, EffectFade],
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
  }

  function setTopSlide() {
    let _icScroll = document.querySelector('.ic_scroll');
    let _intro = document.getElementById('intro');
    let _mv = document.getElementById('mv');
    let _bgSlide = document.getElementById('bgSlide');
    let _topContents = document.getElementById('topContents');

    let check = _bgSlide ? true : false;
    let photoSlide;

    if (check) {
      let photoTimer;
      let photoCurrent = 0;
      let photoBefore = 0;
      let deltaY = 0;
      let endFlag = false;

      const _photoImg = _bgSlide.querySelectorAll('.slide');

      const opening = {
        init: () => {
          // _logo.classList.add('hide');
          // _btnMenu.classList.add('hide');

          // _logo.classList.add('white');
          // _btnMenu.classList.add('white');

          _body.classList.add('noscroll');

          _icScroll.addEventListener('click', () => {
            opening.end();
          });

          window.addEventListener('scroll', (e) => {
            opening.scroll();
          });

          const mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
          window.addEventListener(mousewheelevent, (e) => {
            deltaY += e.deltaY;

            if (deltaY > 200) {
              opening.end();
              return;
            }
          });

          let startY = 0;
          let endY = 0;
          window.addEventListener('touchstart', (e) => {
            startY = e.touches[0].pageY;
          });

          window.addEventListener('touchmove', (e) => {
            endY = e.touches[0].pageY;
          });

          window.addEventListener('touchend', (e) => {
            if (startY - endY > 0) {
              // console.log('down');
              opening.end();
              return;
            } else {
              // console.log('up');
            }
          });

          if (location.search != '') {
            opening.end();
          }
        },

        end: () => {
          if (endFlag) return;
          endFlag = true;

          _intro.classList.add('hide');
          _mv.classList.add('show');
          _lang.classList.add('white');

          _logo.classList.remove('hide');
          _btnMenu.classList.remove('hide');

          setTimeout(() => {
            _body.classList.remove('noscroll');
          }, 1000);
        },

        scroll: () => {
          const offset = _topContents.getBoundingClientRect().top + window.scrollY;

          if (window.scrollY > offset - 100) {
            _logo.classList.remove('white');
            _btnMenu.classList.remove('white');
            _lang.classList.remove('white');
          } else {
            _logo.classList.add('white');
            _btnMenu.classList.add('white');
            _lang.classList.add('white');
          }
        },
      };

      photoSlide = {
        init: () => {
          _photoImg[0].classList.add('active');
          photoSlide.start();
        },

        start: () => {
          _photoImg[0].classList.add('active');

          photoTimer = setInterval(() => {
            photoCurrent++;
            if (photoCurrent > _photoImg.length - 1) photoCurrent = 0;
            photoSlide.change(photoCurrent);
          }, 5000);
        },

        stop: () => {
          clearInterval(photoTimer);

          setTimeout(() => {
            _photoImg.forEach((el) => {
              el.classList.remove('active');
              el.classList.remove('before');
              el.classList.remove('current');
            });
          }, 400);
        },

        change: () => {
          _photoImg.forEach((el) => {
            el.classList.remove('before');
            el.classList.remove('current');
          });

          _photoImg[photoCurrent].classList.add('active');
          _photoImg[photoCurrent].classList.add('current');
          _photoImg[photoCurrent].classList.remove('before');

          setTimeout(() => {
            _photoImg[photoBefore].classList.remove('active');
            _photoImg[photoBefore].classList.remove('current');
            _photoImg[photoBefore].classList.add('before');
            photoBefore = photoCurrent;
          }, 1000);
        },
      };

      opening.init();
      photoSlide.init();
    } else {
    }
  }

  function naviClose() {
    if (namespace != 'top') {
      _body.classList.remove('noscroll');
    }

    _btnMenu.classList.remove('active');
    _gnavi.classList.remove('active');

    _gnavi.querySelectorAll('[data-target]').forEach((el) => {
      removeAction(el);
    });

    naviOpen = false;
  }

  // ----------------------------------------------------------
  // その他ユーティリティ
  // ----------------------------------------------------------
  function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
      setTimeout(() => {
        done();
      }, n);
    });
  }

  const addAction = (target, index) => {
    const delay = 100 * (index + 1);

    target.style.transitionDelay = `${delay}ms`;
    target.classList.add('action');
    target.addEventListener('transitionend', () => {
      target.style.transitionDelay = ``;
    });
  };

  const removeAction = (target) => {
    target.classList.remove('action');
  };

  // load
  window.addEventListener('load', () => {
    utils.setFillHeight();

    initSettings();

    // const INC = new Include();
    // const path = '/wp/wp-content/themes/clips/assets/tpl/';
    // const loadSVG = INC.load(`${path}svg.html`, document.querySelector('#defineSVG'));

    const imgLoad = imagesLoaded(_wrapper, function (instance) {
      initPageTransitions();
    });

    // Promise.all([loadSVG])
    //   .then(function onFulfilled(value) {})
    //   .catch(function onRejected(error) {
    //     // console.error(error);
    //   });
  });

  window.addEventListener('DOMContentLoaded', (event) => {
    // let _logo = document.querySelector('.logo');
    // let _btnMenu = document.getElementById('btnMenu');
    // _logo.classList.add('hide');
    // _btnMenu.classList.add('hide');
  });
})();
