// require('intersection-observer');

export default class ScrollClass {
  constructor(element, toggle = false, rootMargin = '0px', delay = 200) {
    this.dom = element;
    this.toggle = toggle;
    this.rootMargin = rootMargin;
    this.delay = delay;

    this.action();
  }

  update(element) {
    this.dom = element;
    this.action();
  }

  action() {
    const _this = this;
    const options = {
      rootMargin: this.rootMargin,
    };

    if ('IntersectionObserver' in window) {
      let lazyObjectObserver = new IntersectionObserver(function (entries, observer) {
        let count = 0;
        entries.forEach(function (entry, index) {
          let lazyTarget = entry.target;
          let timer;

          if (lazyTarget.dataset.ignore) {
            // console.log(lazyTarget, lazyTarget.dataset.ignore);
            return;
          }

          if (_this.toggle) {
            if (entry.isIntersecting) {
              // transition-delayでタイミング調整
              count++;
              lazyTarget.style.transitionDelay = `${_this.delay * count}ms`;
              lazyTarget.classList.add('action');
              lazyTarget.addEventListener('transitionend', () => {
                lazyTarget.style.transitionDelay = ``;
              });

              // setTimeoutでタイミング調整
              // count++;
              // setTimeout(() => {
              //   lazyTarget.classList.add('action');
              // }, _this.delay * count);
            } else {
              lazyTarget.classList.remove('action');
            }
          } else {
            if (lazyTarget.classList.contains('action')) return;

            if (entry.isIntersecting) {
              // transition-delayでタイミング調整
              count++;
              lazyTarget.style.transitionDelay = `${_this.delay * count}ms`;
              lazyTarget.classList.add('action');

              lazyTarget.addEventListener('transitionend', () => {
                lazyTarget.style.transitionDelay = ``;
              });

              // setTimeoutでタイミング調整
              // clearTimeout(timer);
              // timer = setTimeout(() => {
              //   lazyTarget.classList.add('action');
              // }, _this.delay * count);
              // lazyObjectObserver.unobserve(lazyTarget);
              // count++;
            }
          }
        });
      }, options);

      const node = Array.prototype.slice.call(_this.dom, 0);
      node.forEach((lazyTarget, index) => {
        lazyObjectObserver.observe(lazyTarget);
      });
    }
  }
}
