// require('intersection-observer');

export default class lazyLoad {
  constructor(rootMargin = '0px') {
    this.rootMargin = rootMargin;

    this.init();
  }

  update(element) {
    this.dom = element;
    this.init();
  }

  init() {
    const lazyImages = [].slice.call(document.querySelectorAll('[data-lzy]'));

    // lazyImages.forEach((el)=> {
    //   el.dataset.src = el.getAttribute('src');
    // });

    if ('IntersectionObserver' in window) {
      const options = {
        rootMargin: this.rootMargin,
      };

      let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;

            if (lazyImage.classList.contains('action')) return;

            lazyImage.onload = function (e) {
              // e.target.classList.remove('lzy');
              e.target.classList.add('action');
            };

            if (lazyImage.dataset.src !== undefined) {
              lazyImage.src = lazyImage.dataset.src;
            }

            if (lazyImage.dataset.srcset !== undefined) {
              lazyImage.srcset = lazyImage.dataset.srcset;
            }
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      }, options);

      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    }
  }
}
